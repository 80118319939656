import type { ComponentProps } from 'react';

import { ReactiveBase } from '@appbaseio/reactivesearch';
import { reactiveSearchHost } from '@config/reactive-search';

type SearchBaseProps = Pick<
    ComponentProps<typeof ReactiveBase>,
    'app' | 'setSearchParams' | 'getSearchParams' | 'children'
>;

const SearchBase = (props: SearchBaseProps) => {
    const origin =
        typeof window !== 'undefined' && window.location.origin
            ? window.location.origin
            : 'http://localhost:3000';
    if (isValidUrl(reactiveSearchHost)) {
        return (
            <ReactiveBase
                // @ts-expect-error
                httpRequestTimeout={5}
                url={reactiveSearchHost}
                app={props.app}
                setSearchParams={props.setSearchParams}
                getSearchParams={props.getSearchParams}
            >
                {props.children}
            </ReactiveBase>
        );
    }
    return (
        <ReactiveBase
            url={origin + reactiveSearchHost}
            app={props.app}
            setSearchParams={props.setSearchParams}
            getSearchParams={props.getSearchParams}
        >
            {props.children}
        </ReactiveBase>
    );
};

function isValidUrl(url: string) {
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}

export default SearchBase;
