export const authEnabled = process.env
    .NEXT_PUBLIC_WEBAPP_UI_AUTH_ENABLED as string;
export const reactiveSearchHost = process.env
    .NEXT_PUBLIC_REACTIVE_SEARCH_PROXY_URL as string;
export const narrationsIndex = process.env
    .NEXT_PUBLIC_NARRATIONS_INDEX_NAME as string;
export const matnsIndex = process.env.NEXT_PUBLIC_MATNS_INDEX_NAME as string;
export const narratorsIndex = process.env
    .NEXT_PUBLIC_NARRATORS_INDEX_NAME as string;
export const commentaryIndex = process.env
    .NEXT_PUBLIC_COMMENTARY_INDEX_NAME as string;
export const chainsIndex = process.env.NEXT_PUBLIC_CHAINS_INDEX_NAME as string;
export const chainsLinksIndex = process.env
    .NEXT_PUBLIC_CHAINS_LINKS_INDEX_NAME as string;
export const explanationIndex = process.env
    .NEXT_PUBLIC_EXPLANATION_INDEX_NAME as string;
export const hadithCommentaryIndex = process.env
    .NEXT_PUBLIC_HADITH_COMMENTARY_INDEX_NAME as string;
export const referencesIndex = process.env
    .NEXT_PUBLIC_REFERENCES_INDEX_NAME as string;
export const chainsGraphIndex = process.env
    .NEXT_PUBLIC_CHAINS_GRAPH_INDEX_NAME as string;
export const rulingsIndex = process.env
    .NEXT_PUBLIC_RULINGS_INDEX_NAME as string;
export const ambiguousIndex = process.env
    .NEXT_PUBLIC_AMBIGUOUS_INDEX_NAME as string;
