import { type MutableRefObject } from 'react';

import { type IUserEdge, type IUserNode } from '@antv/graphin';

import { z } from 'zod';

export interface GraphData {
    nodes: NodeObject[];
    edges: CustomLinkObject[];
}

export type DagMode = 'td' | 'bu' | 'lr' | 'rl' | 'radialout' | 'radialin';

export const NodeType = z.enum([
    'Hadith',
    'Author',
    'Narration',
    'Narrator',
    'Prophet',
]);

export type NodeType = z.infer<typeof NodeType>;

export const NodeObject = z.object({
    id: z.string().or(z.number()).optional(),
    x: z.number().optional(),
    y: z.number().optional(),
    vx: z.number().optional(),
    vy: z.number().optional(),
    fx: z.number().optional(),
    fy: z.number().optional(),
});

export type NodeObject = z.infer<typeof NodeObject>;

export const CustomNodeObjectProperties = z.object({
    id: z.number().optional(),
    name: z.string().optional().nullable(),
    is_companion: z.boolean().optional(),
    full_name: z.string().optional().nullable(),
    extended_full_name: z.string().optional().nullable(),
    nickname: z.string().optional().nullable(),
    origin: z.string().optional().nullable(),
    lived_in: z.string().optional().nullable(),
    died_in: z.string().optional().nullable(),
    died_on: z.number().optional().nullable(),
    born_in: z.string().optional().nullable(),
    born_on: z.number().optional().nullable(),
    commentary: z.array(z.string()).optional().nullable(),
    // type: NodeType,
});

export type CustomNodeObjectProperties = z.infer<
    typeof CustomNodeObjectProperties
>;

export const CustomNodeObject = NodeObject.extend({
    id: z.string(),
    node_type: NodeType,
    label: z.string().nullable().optional(),
    is_unknown: z.boolean(),
    is_companion: z.boolean(),
    color: z.string().optional(),
    type: z.string().default('graphin-circle'),
});

export type CustomNodeObject = z.infer<typeof CustomNodeObject>;

export const CustomLinkObjectProperties = z.object({
    narration_id: z.string().optional(),
    sub_narration_id: z.string().optional(),
    verb: z.string().optional().nullable(),
});

export type CustomLinkObjectProperties = z.infer<
    typeof CustomLinkObjectProperties
>;

export const CustomLinkObject = z.object({
    id: z.string(),
    link_type: z.string(),
    source: z.string(),
    target: z.string(),
    properties: CustomLinkObjectProperties,
    curvature: z.number().optional(),
    color: z.string().optional(),
    linkWidth: z.number().optional(),
    arrowLength: z.number().optional(),
    __controlPoints: z.any(),
});

export type CustomLinkObject = z.infer<typeof CustomLinkObject>;

export enum GraphDataSource {
    NEO4J = 'neo4j',
    ELASTIC = 'elastic',
}
export interface ForceGraphProps {
    distanceBetweenSameLevelNodes: number;
    nodeSize?: number;
    fontSize: number;
    canvasSize?: number;
    dagMode?: DagMode;
    hadith_id?: string;
    query?: string;
    disableNodeClick?: boolean;
    disableLinkClick?: boolean;
    pauseAnimationAfterRender?: boolean;
    data: GraphData;
    exportReadyHandler?: (ready: boolean) => void;
    fgRef?: MutableRefObject<any>;
    setGraphCanvas?: (canvas: HTMLCanvasElement) => void;
    isGraphPage?: boolean;
    dataSource: GraphDataSource;
}

export interface NarrationId {
    value: string;
    checked: boolean;
}

export const GraphResultSchema = z.object({
    hadith_id: z.string(),
    nodes: z.array(CustomNodeObject),
    links: z.array(CustomLinkObject),
});

export type GraphResultSchema = z.infer<typeof GraphResultSchema>;

export interface GraphinData {
    nodes: (CustomNodeObject & IUserNode)[];
    edges: (CustomLinkObject & IUserEdge)[];
}

export const GraphResultItemsSchema = z.object({
    data: z.array(GraphResultSchema),
    loading: z.boolean(),
});

export type GraphResultItemsSchema = z.infer<typeof GraphResultItemsSchema>;
