// @ts-nocheck
import {
    CustomLinkObject,
    CustomNodeObject,
    GraphResultSchema,
    NodeType,
} from './GraphInterfaces';

export const getNodesLinks = (
    total_graphs: GraphResultSchema[],
): GraphResultSchema => {
    const links: CustomLinkObject[] = total_graphs.reduce((a, { edges }) => {
        const _links = edges.map((link) => {
            return {
                ...link,
                type: link.type,
                linkWidth: 3,
                arrowLength: 15,
                properties: link.properties,
            };
        });
        return a.concat(_links);
    }, []);

    const nodes: Record<string, CustomNodeObject> = total_graphs.reduce(
        (a, { nodes }) => {
            const nodesObj = nodes.reduce((obj, node) => {
                return {
                    ...obj,
                    [node.id]: {
                        ...node,
                        type: node.type,
                        label: getNodeLabel(
                            node.label,
                            node.node_type as NodeType,
                            node.properties,
                        ),
                        color: getNodeColor(
                            node.node_type as NodeType,
                            node.properties,
                        ),
                        labels: node.labels,
                    },
                };
            }, {} as Record<string, CustomNodeObject>);
            return { ...a, ...nodesObj };
        },
        {},
    );
    return {
        nodes: Object.values(nodes),
        edges: sortLinks(getCurvedLinks(links)),
    };
};

export const normalizeArabicString = (str: string) => {
    return str
        ? str.replace(
              /([^\u0621-\u063A\u0641-\u064A\u0660-\u0669a-zA-Z 0-9])/g,
              '',
          )
        : '';
};

export const getNodeLabel = (
    label: string,
    nodeType: NodeType,
    properties: any,
) => {
    if (label) return label;
    if (nodeType && nodeType === NodeType.Enum.Prophet) {
        return properties.name;
    } else if (nodeType && nodeType === NodeType.Enum.Author) {
        return properties.name;
    } else if (nodeType && nodeType === NodeType.Enum.Narrator && properties) {
        return properties.full_name
            ? properties.full_name
            : properties.name
            ? properties.name
            : properties.nickname
            ? properties.nickname
            : properties.id;
    } else return 'راوي ناقص!';
};

export const getNodeColor = (nodeType: NodeType, isCompanion: boolean) => {
    if (nodeType === NodeType.Enum.Prophet) {
        return '#1490e3';
    } else if (nodeType === NodeType.Enum.Author) {
        return '#c7c7c7';
    } else if (nodeType === NodeType.Enum.Narrator) {
        return isCompanion ? '#e3821b' : '#19b816';
    }
};

export const getCurvedLinks = (links: CustomLinkObject[]) => {
    // map of edges that have the same start and end vertices
    const commonLinksMap: Record<string, CustomLinkObject[]> = {};
    links.forEach((link: CustomLinkObject) => {
        if (link.source.id) {
            if (`${link.source.id}-${link.target.id}` in commonLinksMap)
                commonLinksMap[`${link.source.id}-${link.target.id}`] =
                    commonLinksMap[
                        `${link.source.id}-${link.target.id}`
                    ].concat(link);
            else commonLinksMap[`${link.source.id}-${link.target.id}`] = [link];
        } else {
            if (`${link.source}-${link.target}` in commonLinksMap)
                commonLinksMap[`${link.source}-${link.target}`] =
                    commonLinksMap[`${link.source}-${link.target}`].concat(
                        link,
                    );
            else commonLinksMap[`${link.source}-${link.target}`] = [link];
        }
    });

    let curvedLinks: CustomLinkObject[] = [];

    // if 2 vertices have more than 2 edges between them, set curvature on edge
    for (const key of Object.keys(commonLinksMap)) {
        const commonLinks: CustomLinkObject[] = commonLinksMap[key];

        if (commonLinks.length % 2 === 1 || commonLinks.length === 1) {
            const link = commonLinks.shift();
            link.curvature = 0;
            curvedLinks = curvedLinks.concat(link);
        }

        if (commonLinks.length > 0) {
            let start = 0;
            let end = commonLinks.length - 1;

            let increment = 1 / commonLinks.length;
            let curvature = increment;

            while (start < end) {
                commonLinks[start].curvature = -1 * curvature;
                commonLinks[end].curvature = curvature;
                curvature += increment;

                start += 1;
                end -= 1;
            }
        }
        curvedLinks = curvedLinks.concat(commonLinks);
    }

    return curvedLinks;
};

export const sortLinks = (links: CustomLinkObject[]): CustomLinkObject[] => {
    return links.sort((a, b) =>
        a.properties.narration_id < b.properties.narration_id ? -1 : 1,
    );
};

// build map of narration_id, links
export const buildNarrationIdLinksMap = (
    edges: CustomLinkObject[],
): Record<string, CustomLinkObject[]> => {
    const narrationIdLinksMap: Record<string, CustomLinkObject[]> =
        edges.reduce((map: Record<string, CustomLinkObject[]>, link) => {
            const narrationId = link.properties.narration_id;
            if (map[narrationId]) {
                map[narrationId] = map[narrationId].concat(link);
            } else {
                map[narrationId] = [link];
            }
            return map;
        }, {});

    return narrationIdLinksMap;
};
//     properties: CustomLinkObjectProperties | CustomNodeObjectProperties,
// ) => {
//     if (properties) {
//         const linkLabel = (
//             <div
//                 style={{
//                     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//                     color: 'black',
//                     padding: '10px',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     gap: '20px',
//                     minWidth: '250px',
//                 }}
//             >
//                 <div>
//                     <span style={{ fontWeight: '700' }}>Properties</span>
//                 </div>
//                 <div
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'column',
//                         gap: '10px',
//                     }}
//                 >
//                     {Object.entries(properties).map(([key, value]) => {
//                         return (
//                             <div
//                                 key={key}
//                                 style={{
//                                     display: 'flex',
//                                     flexDirection: 'row',
//                                     justifyContent: 'space-between',
//                                     gap: '20px',
//                                     borderBottom:
//                                         '1px rgb(206, 206, 206) solid',
//                                 }}
//                             >
//                                 <span style={{ maxWidth: '100px' }}>{key}</span>
//                                 <span>{value?.low ? value?.low : value}</span>
//                             </div>
//                         );
//                     })}
//                 </div>
//             </div>
//         );

//         return ReactDOMServer.renderToString(linkLabel);
//     } else {
//         return 'undefined';
//     }
// };
