export enum MatnDataFields {
    EXT_NARRATIONS = 'extended_narrations',
    MATN_WITH_TASHKEEL = 'matn_with_tashkeel',
    NARRATIONS = 'narrations',
    RAW_NARRATIONS = 'raw_narrations',
    BOOK_NAME = 'book_name',
    HADITH_BOOK_NAME = 'hadith_book_name',
    CONSOLIDATED_MATN = 'consolidated_matn',
    FULL_CONSOLIDATED_MATN = 'full_consolidated_matn',
    RERANK_TEXT = 'rerank_text',
    HADITH_TYPE = 'hadith_type',
    TYPE = 'type',
    NUMBER = 'number',
    HADITH_ID = 'hadith_id',
    HADITH_SERIAL_ID = 'hadith_serial_id',
    NARRATORS = 'narrators',
    HADITH = 'hadith',
    VOLUME = 'volume',
    PAGE = 'page',
    CHAPTER = 'chapter',
    SUB_CHAPTER = 'sub_chapter',
    HAS_EXPLANATION = 'hasExplanation',
    HAS_EXTENDED_EXPLANATION = 'hasExtendedExplanation',
    HAS_COMMENTARY = 'hasCommentary',
    HAS_RULING = 'hasRuling',
    HAS_EXTENDED_RULING = 'hasExtendedRuling',

    RULINGS = 'rulings',
    RULINGS_RULER = 'rulings.ruler.keyword',
    RULINGS_RULING = 'rulings.ruling.keyword',
    RULINGS_BOOK_NAME = 'rulings.book_name',
    RULINGS_PAGE = 'rulings.page',
    RULINGS_VOLUME = 'rulings.volume',

    HADITH_AMBIGUOUS = 'ambiguous',
    EDITIONS = 'editions',
}

export enum NarrationsDataFields {
    AUTHENTICITY_ORDER = 'authenticity_order',
    AUTHOR = 'author',
    BOOK_NAME = 'book_name',
    HAS_EXPLANATION = 'hasExplanation',
    HAS_EXTENDED_EXPLANATION = 'hasExtendedExplanation',
    HAS_COMMENTARY = 'hasCommentary',
    HAS_RULING = 'hasRuling',
    HAS_EXTENDED_RULING = 'hasExtendedRuling',
    HADITH = 'hadith',
    HADITH_TOKENIZED = 'hadith.tokenized',
    HADITH_EXACT = 'hadith.exact',
    GRAPH = 'graph',
    CHAPTER = 'chapter',
    CHAPTER_NORMALIZED = 'chapter.normalized',
    HADITH_ID = 'hadith_id',
    HADITH_SERIAL_ID = 'hadith_serial_id',
    NUMBER = 'number',
    MATN = 'matn',
    MATN_WITH_TASHKEEL = 'matn_with_tashkeel',
    NARRATION_ID = 'narration_id',
    KEYWORDS = 'keywords',
    ROOT_KEYWORDS = 'root_keywords',
    ROOT_KEYWORDS_NORMALIZED = 'root_keywords.normalized',
    NARRATIONS = 'narrations',
    EXT_NARRATIONS = 'extended_narrations',
    RAW_NARRATIONS = 'raw_narrations',
    NARRATIONS_NUMBERS = 'narrations_numbers',
    NARRATORS = 'narrators',
    NARRATORS_FULL_NAME = 'narrators.full_name',
    NARRATORS_FULL_NAME_NORMALIZED = 'narrators.full_name.normalized',
    NARRATORS_FULL_NAME_WITHOUT_TASHKEEL = 'narrators.full_name_without_tashkeel',
    NARRATORS_GRADE = 'narrators.grade',
    NARRATORS_ID = 'narrators.id',
    NARRATORS_IS_COMPANION = 'narrators.is_companion',
    NARRATORS_LEVEL = 'narrators.level',
    NARRATORS_REFERENCE = 'narrators.reference',
    NARRATORS_REFERENCE_WITHOUT_TASHKEEL = 'narrators.reference_without_tashkeel',
    NARRATORS_FULL_NAMES = 'narrators_full_names',
    PAGE = 'page',
    RULING = 'ruling',
    RULING_NORMALIZED = 'ruling.normalized',
    SUB_CHAPTER = 'sub_chapter',
    SUB_CHAPTER_NORMALIZED = 'sub_chapter.normalized',
    TIME_ORDER = 'time_order',
    TYPE = 'type',
    VOLUME = 'volume',
    NARRATIONS_GROUP_ID = 'narrations_group_id',

    RULINGS = 'rulings',
    RULINGS_RULER = 'rulings.ruler.keyword',
    RULINGS_RULING = 'rulings.ruling.keyword',
    RULINGS_BOOK_NAME = 'rulings.book_name',
    RULINGS_PAGE = 'rulings.page',
    RULINGS_VOLUME = 'rulings.volume',

    HADITH_AMBIGUOUS = 'ambiguous',

    EDITIONS = 'editions',
}

export enum HadithExplanationDataFields {
    HADITH = 'hadith',
    EXPLANATION = 'explanation',

    HADITH_HADITH = 'hadith.hadith',
    HADITH_BOOK_NAME = 'hadith.book_name',
    HADITH_CHAPTER = 'hadith.chapter',
    HADITH_CHAPTER_NORMALIZED = 'hadith.chapter.normalized',
    HADITH_SUB_CHAPTER = 'hadith.sub_chapter',
    HADITH_SUB_CHAPTER_NORMALIZED = 'hadith.sub_chapter.normalized',
    HADITH_HADITH_ID = 'hadith.hadith_id',
    HADITH_NUMBER = 'hadith.number',
    HADITH_HADITH_SERIAL_ID = 'hadith.hadith_serial_id',
    HADITH_HADITH_SERIAL_ID_KEYWORD = 'hadith.hadith_serial_id.keyword',
    HADITH_TYPE = 'hadith.type',
    HADITH_NARRATORS = 'hadith.narrators',
    HADITH_AUTHENTICITY_ORDER = 'hadith.authenticity_order',
    HADITH_EXPLANATION_AUTHENTICITY_ORDER = 'hadith.explanation_authenticity_order',
    EXPLANATION_BOOK_AUTHOR = 'explanation.explanation_book_author',
    EXPLANATION_BOOK_NAME = 'explanation.explanation_book_name',
    EXPLANATION_VOLUME = 'explanation.explanation_volume',
    EXPLANATION_PAGE = 'explanation.explanation_page',
    EXPLANATION_HADITH_EXPLANATION_ARRAY = 'explanation.hadith_explanation_array',
}

export enum HadithCommentaryDataFields {
    TYPE = 'type',
    HADITH = 'hadith',
    COMMENTARY = 'commentary',
    SERIAL_ID = 'serial_id',
    TIME_ORDER = 'time_order',

    HADITH_HADITH = 'hadith.hadith',
    HADITH_BOOK_NAME = 'hadith.book_name',
    HADITH_CHAPTER = 'hadith.chapter',
    HADITH_SUB_CHAPTER = 'hadith.sub_chapter',
    HADITH_HADITH_ID = 'hadith.hadith_id',
    HADITH_NUMBER = 'hadith.number',
    HADITH_HADITH_SERIAL_ID = 'hadith.hadith_serial_id',
    HADITH_HADITH_SERIAL_ID_KEYWORD = 'hadith.hadith_serial_id.keyword',
    HADITH_TYPE = 'hadith.type',
    HADITH_NARRATORS = 'hadith.narrators',
    HADITH_AUTHENTICITY_ORDER = 'hadith.authenticity_order',
    HADITH_VOLUME = 'hadith.volume',
    HADITH_PAGE = 'hadith.page',
    HADITH_EDITIONS = 'hadith.editions',

    COMMENTARY_ID = 'commentary.id',
    COMMENTARY_NARRATORS = 'commentary.narrators',
    COMMENTARY_NARRATIONS = 'commentary.narrations',
    COMMENTARY_PAGE = 'commentary.page',
    COMMENTARY_VOLUME = 'commentary.volume',
    COMMENTARY_BOOK_NAME = 'commentary.book_name',
    COMMENTARY_AUTHOR_NAME = 'commentary.author_name',
    COMMENTARY_MATN = 'commentary.matn',
    COMMENTARY_TEXT = 'commentary.commentary_text',
    COMMENTARY_FULL_TEXT = 'commentary.full_text',
}

export enum HadithRulingsDataFields {
    AUTHENTICITY_ORDER = 'authenticity_order',
    AUTHOR = 'author',
    BOOK_NAME = 'book_name',
    HADITH = 'hadith',
    CHAPTER = 'chapter',
    PAGE = 'page',
    VOLUME = 'volume',
    CHAPTER_NORMALIZED = 'chapter.normalized',
    HADITH_ID = 'hadith_id',
    NARRATIONS_TYPE = 'narrations_type',
    HADITH_SERIAL_ID = 'hadith_serial_id',
    NUMBER = 'number',
    NARRATIONS = 'narrations',
    RAW_NARRATIONS = 'raw_narrations',
    NARRATIONS_NUMBERS = 'narrations_numbers',
    NARRATORS = 'narrators',
    NARRATORS_FULL_NAME = 'narrators.full_name',
    NARRATORS_FULL_NAME_NORMALIZED = 'narrators.full_name.normalized',
    NARRATORS_FULL_NAME_WITHOUT_TASHKEEL = 'narrators.full_name_without_tashkeel',
    NARRATORS_GRADE = 'narrators.grade',
    NARRATORS_ID = 'narrators.id',
    NARRATORS_IS_COMPANION = 'narrators.is_companion',
    NARRATORS_LEVEL = 'narrators.level',
    NARRATORS_REFERENCE = 'narrators.reference',
    NARRATORS_REFERENCE_WITHOUT_TASHKEEL = 'narrators.reference_without_tashkeel',
    NARRATORS_FULL_NAMES = 'narrators_full_names',
    RULINGS = 'rulings',
    RULER = 'ruler',
    RULER_KEYWORD = 'ruler.keyword',
    RULINGS_RULING = 'rulings.ruling.keyword',
    SUB_CHAPTER = 'sub_chapter',
    SUB_CHAPTER_NORMALIZED = 'sub_chapter.normalized',
    TIME_ORDER = 'time_order',
    TYPE = 'type',
    RULER_DOD = 'ruler_dod',
    RULINGS_BOOK_NAME = 'rulings.book_name',
    RULINGS_BOOK_EDITION = 'rulings.book_edition',
    RULINGS_BOOK_PICTURES_PATH = 'rulings.book_pictures_path',
    RULINGS_PAGE = 'rulings.page',
    RULINGS_VOLUME = 'rulings.volume',
}

export enum NarratorsDataFields {
    ID = 'id',
    NARRATIONS_COUNT = 'narrations_count',
    BORN_ON = 'born_on',
    DIED_IN = 'died_in',
    DIED_ON = 'died_on',
    GRADE = 'grade',
    EXTENDED_FULL_NAME = 'extended_full_name',
    FULL_NAME = 'full_name',
    FULL_NAME_NORMALIZED = 'full_name.normalized',
    IS_COMPANION = 'is_companion',
    IS_UNKNOWN = 'is_unknown',
    LEVEL = 'level',
    HAS_COMMENTARY = 'hasCommentary',
    LIVED_IN = 'lived_in',
    NICKNAME = 'nickname',
    BOOK_TITLES = 'book_titles',
    ORIGIN = 'origin',
    TITLE = 'title',
    COMMENTARY = 'commentary',
    TOP_STUDENTS = 'top_students',
    TOP_SCHOLARS = 'top_scholars',
}

export enum NarratorsCommentaryDataFields {
    ID = 'id',
    AUTHOR = 'author',
    BOOK = 'book',
    COMMENTS = 'comments',
    COMMENTER = 'commenter',
    BOOK_ORDER = 'book_order',
    COMMENTER_DOD = 'commenter_dod',
    COMMENTER_NORMALIZED = 'commenter.normalized',
    NAME = 'name',
    PAGE = 'page',
    VOLUME = 'volume',
}

export enum AmbiguousDataFields {
    ID = 'id',
    BOOK_NAME = 'book_name',
    AUTHOR = 'author',
    EXPLANATION = 'explanation',
    PAGE = 'page',
    VOLUME = 'volume',
}

export enum NarrationsLinksDataFields {
    HADITH = 'hadith',
    LINK = 'link',

    HADITH_HADITH = 'hadith.hadith',
    HADITH_BOOK_NAME = 'hadith.book_name',
    HADITH_CHAPTER = 'hadith.chapter',
    HADITH_SUB_CHAPTER = 'hadith.sub_chapter',
    HADITH_HADITH_ID = 'hadith.hadith_id',
    HADITH_HADITH_SERIAL_ID = 'hadith.hadith_serial_id',
    HADITH_HADITH_SERIAL_ID_KEYWORD = 'hadith.hadith_serial_id.keyword',
    HADITH_TYPE = 'hadith.type',
    HADITH_NARRATORS = 'hadith.narrators',
    HADITH_AUTHENTICITY_ORDER = 'hadith.authenticity_order',
    LINK_SRC_REFERENCE = 'link.src_reference',
    LINK_TGT_REFERENCE = 'link.tgt_reference',
    LINK_SRC_ID = 'link.src_id',
    LINK_TGT_ID = 'link.tgt_id',
    NARRATIONS = 'hadith.narrations',

    LINK_SRC_FULL_NAME = 'link.src_full_name',
    LINK_TGT_FULL_NAME = 'link.tgt_full_name',
    LINK_SRC_GRADE = 'link.src_grade',
    LINK_TGT_GRADE = 'link.tgt_grade',
    LINK_VERB = 'link.verb',
    LINK_IS_SINGULAR = 'link.is_singular',

    //Used for searching filter items with normalizations (Normalized hamza, etc..)
    LINK_SRC_FULL_NAME_NORMALIZED = 'link.src_full_name.normalized',
    LINK_TGT_FULL_NAME_NORMALIZED = 'link.tgt_full_name.normalized',
    LINK_SRC_GRADE_NORMALIZED = 'link.src_grade.normalized',
    LINK_TGT_GRADE_NORMALIZED = 'link.tgt_grade.normalized',
    LINK_VERB_NORMALIZED = 'link.verb.normalized',

    NARRATIONS_GROUP_ID = 'hadith.narrations_group_id',

    RULINGS = 'rulings',
    RULINGS_RULER = 'rulings.ruler.keyword',
    RULINGS_RULING = 'rulings.ruling.keyword',
    RULINGS_BOOK_NAME = 'rulings.book_name',
    RULINGS_PAGE = 'rulings.page',
    RULINGS_VOLUME = 'rulings.volume',
}

export enum ReferencesDataFields {
    ID = '_id',
    AUTHOR = 'author',
    AUTHOR_KEYWORD = 'author.keyword',
    BOOK_NAME = 'book_name',
    BOOK_NAME_KEYWORD = 'book_name.keyword',
    TYPE = 'type',
    TYPE_KEYWORD = 'type.keyword',
    STATUS = 'status',
    STATUS_KEYWORD = 'status.keyword',
    PROGRESS = 'progress',
    EDITION = 'edition',
    // FULL_BOOK_NAME = 'fullBookName',
    // NUM_OF_PARTS = 'numOfParts',
    // PUBLISHER = 'publisher',
    // VERIFIER = 'verifier',
}

export enum GraphDataFields {
    HADITH_ID = 'hadith_id',
    NODES = 'nodes',
    LINKS = 'links',
    HADITH = 'hadith',
    BOOK_NAME = 'hadith.book_name',
    TYPE = 'hadith.type',
    NARRATORS_FULL_NAMES = 'hadith.narrators_full_names',
    CHAPTER = 'hadith.chapter',
    SUB_CHAPTER = 'hadith.sub_chapter',
    NARRATORS = 'hadith.narrators',
    NARRATORS_FULL_NAME = 'hadith.narrators.full_name',
    NARRATORS_FULL_NAME_NORMALIZED = 'hadith.narrators.full_name.normalized',
    CHAPTER_NORMALIZED = 'hadith.chapter.normalized',
    SUB_CHAPTER_NORMALIZED = 'hadith.sub_chapter.normalized',
    CHAINS_TEXT = 'chain_text',
}
